import React from 'react'
import Layout from '../../staticcomponents/layout'
import Container from '@material-ui/core/Container';
import Link from '@mui/material/Link';
import './PageNotFound.scss'


export default function PageNotFound() {
    return (
        <>
            <Layout>
              <div className='page-not-found capsules-bg'>
                <Container maxWidth="xl">
                    <h1>Page not found</h1>
                    <h3>Oops, no page could be found at this address.</h3>
                    <Link href="/" className='home_btn'>back to home</Link>
                </Container>
              </div>
            </Layout>
        </>
    )
}